<script setup lang="ts">
import { mapeamentoAtos } from '~/types/Orcamento/const/MapeamentoAtos'
import type CampoFormulario from '~/types/Orcamento/CampoFormulario'
const store = useOrcamentoStore()
const { modalAdicionarAto, listaDeCamposExtra } = storeToRefs(store)

const props = defineProps({
  ato: {
    type: Object,
    required: true,
  },
  camposExtra: {
    type: Array,
    required: false,
    default: () => [],
  },
})

function tipoDeCampo (campo: CampoFormulario) {
  if (campo.tipo === 'TEXTO_CURTO') {
    return 'text'
  }
  return 'number'
}

function verificarSeAtoPossuiCampoAdicional (ato, campo) {
  return mapeamentoAtos[ato.codigo] && mapeamentoAtos[ato.codigo].includes(campo)
}

const verificarSeAtoPossuiCampoValor = computed(() => {
  const campoValor = props.camposExtra.some((campo: CampoFormulario) => ['valor_economico', 'valor_fiscal', 'valor_avaliacao'].includes(campo.nome))
  return campoValor
})

const camposExtraParaExibir = computed(() => {
  if (props.camposExtra.some((campo: CampoFormulario) => campo.nome === 'fator')) {
    const campo = props.camposExtra.filter((campo: CampoFormulario) => {
      return campo.nome === 'fator' && (mapeamentoAtos[props.ato.codigo]?.includes('temFatorAcrescimo') || mapeamentoAtos[props.ato.codigo]?.includes('temFatorReducao')) && campo.obrigatorio === 1
    })
    return campo
  }

  const campos = props.camposExtra.filter((campo: CampoFormulario) => possuiCampoExtra(campo.nome) && campo.obrigatorio === 1 && !['valor_economico', 'valor_fiscal', 'valor_avaliacao'].includes(campo.nome))
  return campos
})

function possuiCampoExtra (campo: string) {
  return listaDeCamposExtra.value.includes(campo)
}

function getCampoOpcoes (campo: CampoFormulario) {
  if (campo.tipo === 'SELECT') {
    const opcoes = JSON.parse(campo.opcoes)
    return Object.keys(opcoes).reduce((acc, key) => {
      acc[opcoes[key]] = key
      return acc
    }, {})
  }
}
</script>
<template>
  <CRow>
    <CCol v-for="campo, index in camposExtraParaExibir" :key="index" :md="6">
      <div class="form-group">
        <label class="mb-2">{{ campo.rotulo }}*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado[campo.nome] }" class="fg-line">
          <select
            v-if="campo.tipo === 'SELECT'"
            v-model="modalAdicionarAto.atoSelecionado[campo.nome]"
            class="form-select"
            required
          >
            <option v-for="(valor, rotulo) in getCampoOpcoes(campo)" :key="valor" :value="valor">
              {{ rotulo }}
            </option>
          </select>
          <input
            v-else
            v-model.number="modalAdicionarAto.atoSelecionado[campo.nome]"
            class="form-control"
            step="1"
            :type="tipoDeCampo(campo)"
            :min="campo.valor_minimo"
            :max="campo.valor_maximo"
            required
          >
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoValor" :md="6">
      <div class="form-group">
        <label>Valor do ato*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.valor_ato }" class="fg-line">
          <CInputGroup>
            <CInputGroupPrepend>
              <CInputGroupText>R$</CInputGroupText>
            </CInputGroupPrepend>
            <input
              v-maska
              data-maska="##########,##"
              data-maska-reversed
              class="form-control"
              required
              @maska="modalAdicionarAto.atoSelecionado.valor_ato = $event.detail.unmasked /100"
            >
          </CInputGroup>
        </div>
      </div>
    </CCol>

    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temEnvolvidos')" :md="6">
      <div class="form-group">
        <label>Quantidade de Partes*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.quantidade_envolvidos }" class="fg-line">
          <input
            v-model.number="modalAdicionarAto.atoSelecionado.quantidade_envolvidos"
            class="form-control"
            type="number"
            min="1"
            max="255"
            required
          >
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temEnvolvidosAtos')" :md="6">
      <div class="form-group">
        <label>Quantidade de Atos/Assinaturas/Pessoas*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.quantidade_envolvidos }" class="fg-line">
          <input
            v-model.number="modalAdicionarAto.atoSelecionado.quantidade_envolvidos"
            class="form-control"
            type="number"
            min="1"
            max="255"
            required
          >
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temOutorgantesOutorgados')" :md="6">
      <div class="form-group">
        <label>Quantidade de Partes (Outorgantes/Outorgados)*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.quantidade_envolvidos }" class="fg-line">
          <input
            v-model.number="modalAdicionarAto.atoSelecionado.quantidade_envolvidos"
            class="form-control"
            type="number"
            min="1"
            max="255"
            required
          >
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temKm')" :md="6">
      <div class="form-group">
        <label>Quantidade Kilômetros*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.km }" class="fg-line">
          <input
            v-model.number="modalAdicionarAto.atoSelecionado.km"
            class="form-control"
            type="number"
            min="1"
            required
          >
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temMinutos')" :md="6">
      <div class="form-group">
        <label>Minutos*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.minutos }" class="fg-line">
          <input
            v-model.number="modalAdicionarAto.atoSelecionado.minutos"
            class="form-control"
            type="number"
            min="1"
            max="255"
            required
          >
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temMetroQuadrado')" :md="6">
      <div class="form-group">
        <label>Área (metros quadrados)*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.metro_quadrado }" class="fg-line">
          <input v-model.number="modalAdicionarAto.atoSelecionado.metro_quadrado" class="form-control" type="number" min="1" required>
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temHectare')" :md="6">
      <div class="form-group">
        <label>Área (hectare)*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.hectare }" class="fg-line">
          <input v-model.number="modalAdicionarAto.atoSelecionado.hectare" class="form-control" type="number" min="1" required>
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temUnidadesAutonomas')" :md="6">
      <div class="form-group">
        <label>Qt. Unidade Autônomas*</label>
        <div :class="{ 'fg-toggled': modalAdicionarAto.atoSelecionado.quantidade_unidade_autonomas }" class="fg-line">
          <input
            v-model.number="modalAdicionarAto.atoSelecionado.quantidade_unidade_autonomas"
            type="number"
            class="form-control"
            min="1"
            max="255"
            required
          >
        </div>
      </div>
    </CCol>
    <CCol v-if="verificarSeAtoPossuiCampoAdicional(modalAdicionarAto.atoSelecionado, 'temKBytes')" style="margin-left: 5px;" :md="6">
      <div class="form-group">
        <label>Quantidade KBytes*</label>
        <div>
          <input
            v-model="modalAdicionarAto.atoSelecionado.kbytes"
            class="form-control"
            type="number"
            min="1"
            required
          >
        </div>
      </div>
    </CCol>
  </CRow>
</template>
