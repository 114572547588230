export const useLarkStore = defineStore('larkStore', {
  state: () => ({
    openId: null as string | null,
    calendarId: null as string | null,
    isAuthenticated: false,
    authState: null as string | null,
    tenantKey: null as string | null,
    selectedCalendarId: '' as string,
  }),

  actions: {
    async authenticate () {
      const apiStore = useApiStore()

      // Generate random state for CSRF protection
      this.authState = Math.random().toString(36).substring(7)

      // Get auth URL from backend
      const authData = await apiStore.request('/lark/auth-url', {
        method: 'POST',
        body: { state: this.authState },
      })

      // Calculate center position
      const width = 600
      const height = 600
      const left = (window.innerWidth - width) / 2 + window.screenX
      const top = (window.innerHeight - height) / 2 + window.screenY

      const popup = window.open(
        authData.url,
        'Lark Authentication',
        `width=${width},height=${height},left=${left},top=${top},toolbar=0,location=0,status=0,menubar=0`
      )

      if (!popup) {
        throw new Error('Popup bloqueado pelo navegador')
      }

      // Return promise that resolves when auth is complete
      return new Promise((resolve, reject) => {
        const timer = setInterval(() => {
          if (popup.closed) {
            clearInterval(timer)
            reject(new Error('Autenticação cancelada'))
          }
        }, 500)

        window.addEventListener('message', async (event) => {
          // Verify origin matches your callback page
          if (event.origin !== window.location.origin) { return }

          if (event.data.type === 'lark-auth') {
            clearInterval(timer)
            const { code, state } = event.data
            try {
              const success = await this.handleCallback(code, state)
              popup?.close()
              if (success) {
                window.location.reload()
              } else {
                resolve(false)
              }
            } catch (error) {
              popup?.close()
              reject(error)
            }
          }
        })
      })
    },

    async handleCallback (code: string, state: string) {
      // Verify state to prevent CSRF
      if (state !== this.authState) {
        throw new Error('Invalid state parameter')
      }

      const apiStore = useApiStore()

      // Exchange code for tokens
      const response = await apiStore.request('/lark/callback', {
        method: 'POST',
        body: { code },
      })

      if (response.open_id) {
        this.setUserData({
          openId: response.open_id,
          tenantKey: response.tenant_key,
        })
        return true
      }

      return false
    },

    setUserData ({ openId, tenantKey }: { openId: string, tenantKey: string }) {
      this.openId = openId
      this.tenantKey = tenantKey
      this.isAuthenticated = true
    },

    async createTask (taskData: { title: string, description?: string, due_date?: string }) {
      const apiStore = useApiStore()
      return await apiStore.request('/lark/tasks', {
        method: 'POST',
        body: taskData, // No need to add collaborator_id, backend handles that now
      })
    },

    async listCalendars () {
      try {
        const apiStore = useApiStore()
        const response = await apiStore.request('/lark/calendars')
        return response
      } catch (error) {
        console.error('Error listing calendars:', error)
        throw error
      }
    },

    async getPrimaryCalendar () {
      const apiStore = useApiStore()
      return await apiStore.request('/lark/calendar')
    },

    async createCalendarEvent (calendarId: string, eventData: any, despesa: any) {
      try {
        const apiStore = useApiStore()
        return await apiStore.request('/lark/calendars/events', {
          method: 'POST',
          body: {
            calendarId,
            ...eventData,
            despesa,
            user_id: this.userId,
          },
        })
      } catch (error) {
        console.error('Error creating calendar event:', error.message)
        throw error
      }
    },

    async checkConnectionStatus () {
      try {
        const apiStore = useApiStore()
        const response = await apiStore.request('/lark/status')
        this.isAuthenticated = response.isConnected
        if (response.openId) {
          this.openId = response.openId
        }
        if (response.calendarId) {
          this.calendarId = response.calendarId
          this.selectedCalendarId = response.calendarId
        }
      } catch (error) {
        console.error('Error checking Lark connection status:', error)
      }
    },

    async disconnect () {
      try {
        const apiStore = useApiStore()
        await apiStore.request('/lark/disconnect', {
          method: 'POST',
        })
        this.openId = null
        this.calendarId = null
        this.isAuthenticated = false
        this.authState = null
        this.tenantKey = null
        this.selectedCalendarId = ''
      } catch (error) {
        console.error('Error disconnecting from Lark:', error)
        throw error
      }
    },

    async setDefaultCalendar (calendarId: string) {
      try {
        const apiStore = useApiStore()
        const response = await apiStore.request('/lark/calendar/default', {
          method: 'POST',
          body: { calendar_id: calendarId },
        })

        if (response.success) {
          this.calendarId = response.calendar_id
        }

        return response
      } catch (error) {
        console.error('Error setting default calendar:', error)
        throw error
      }
    },
  },

  persist: {
    paths: ['openId', 'calendarId', 'isAuthenticated', 'authState', 'tenantKey', 'selectedCalendarId'],
  },
})
