<script setup>
const usuarioStore = useUsuarioStore()
defineExpose({
  name: 'DefaultLayout',
})
</script>

<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="body flex-grow-1">
        <Toast />
        <CContainer :key="useStore().key" class="px-4" fluid>
          <NuxtPage :key="usuarioStore.cartorioAtual.id" />
          <Loading />
        </CContainer>
        <!-- <AppFloatingButton /> -->
        <LivroDiarioAuxiliarModalAdicionarReceita />
        <LivroDiarioAuxiliarModalImportarXml />
        <ModalCadastrarCliente />
        <ModalBPOCadastrarCliente />
        <ModalBPOImportarCliente />
        <OrcamentosModalImportarCliente />
        <OrcamentosModalAdicionarAto />
      </div>
      <AppFooter />
    </div>
  </div>
</template>
