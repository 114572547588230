<script lang="ts" setup>
const store = useClienteStore()
const prestacaoDeContasStore = usePrestacaoDeContaStore()
const { modalCadastrarCliente } = storeToRefs(store)
const formCadastrarCliente = ref(null)

const cpfCnpj = {
  onMaska: (detail: MaskaDetail) => {
    modalCadastrarCliente.value.novoCliente.cpf_cnpj = detail.unmasked
  },
}
const telefone = {
  onMaska: (detail: MaskaDetail) => {
    modalCadastrarCliente.value.novoCliente.whatsapp = detail.unmasked
  },
}

async function salvar () {
  try {
    validar()
    const clienteSalvo = await store.salvarCliente()
    prestacaoDeContasStore[store.modelState][store.modelStateKey] = clienteSalvo
    modalCadastrarCliente.value.exibir = false
    useStore().reorganizarModais(store.modalCadastrarCliente)
    useStore().toasts.push({ content: 'Pessoa cadastrada com sucesso', color: 'success' })
  } catch (err) {
    useStore().toasts.push({ content: err.message, color: 'danger' })
  }
}
function validar (): boolean {
  const pessoaFisica = modalCadastrarCliente.value.novoCliente.cpf_cnpj.length === 11
  if (!modalCadastrarCliente.value.novoCliente.cpf_cnpj) {
    throw new Error('Informe o CPF')
  }
  if (pessoaFisica) {
    if (!modalCadastrarCliente.value.novoCliente.nome) {
      throw new Error('Informe o nome')
    }
  } else if (!modalCadastrarCliente.value.novoCliente.razao_social) {
    throw new Error('Informe a razão social')
  }
  return true
}
function cancelar () {
  modalCadastrarCliente.value.exibir = false
  useStore().reorganizarModais(store.modalCadastrarCliente)
}
</script>
<template>
  <CForm ref="formCadastrarCliente" @submit.prevent="salvar">
    <CModal
      :visible="modalCadastrarCliente.exibir"
      aria-labelledby="importarClienteDeOutroCartorioLabel"
      size="lg"
      @close="cancelar"
    >
      <CModalHeader>
        <CModalTitle id="cadastrarClienteLabel">
          Cadastro de pessoa
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol :md="4">
            <CFormLabel :for="'cpfCnpj'">
              <span v-if="modalCadastrarCliente.novoCliente.cpf_cnpj.length !== 14"> CPF* </span>
              <span v-else> CNPJ *</span>
            </CFormLabel>
            <input
              :id="'cpfCnpj'"
              v-maska:[cpfCnpj]
              class="form-control"
              data-maska="['###.###.###-##', '##.###.###/####-##']"
              :value="modalCadastrarCliente.novoCliente.cpf_cnpj"
              required
            >
          </CCol>
          <CCol :md="8">
            <CFormInput
              v-if="modalCadastrarCliente.novoCliente.cpf_cnpj.length !== 14"
              id="nome"
              v-model="modalCadastrarCliente.novoCliente.nome"
              type="text"
              label="Nome*"
              text="Evite abreviaturas"
              aria-describedby="nome"
              required
            />
            <CFormInput
              v-else
              id="razaoSocial"
              v-model="modalCadastrarCliente.novoCliente.razao_social"
              type="text"
              label="Razão Social*"
              text="Evite abreviaturas"
              aria-describedby="razaoSocial"
              required
            />
          </CCol>
        </CRow>
        <CRow v-if="modalCadastrarCliente.novoCliente.cpf_cnpj.length !== 14">
          <CCol :md="2">
            <CFormLabel for="ddi">
              DDI
            </CFormLabel>
            <input id="ddi" v-model="modalCadastrarCliente.novoCliente.whatsapp_ddi" class="form-control" data-maska="['+##']">
          </CCol>
          <CCol :md="4">
            <CFormLabel for="telefone">
              WhatsApp
            </CFormLabel>
            <input
              id="telefone"
              v-maska:[telefone]
              :value="modalCadastrarCliente.novoCliente.whatsapp"
              class="form-control"
              data-maska="['(##) #####-####']"
            >
            <small>Obs.: se não possuir, informar de contato/recado</small>
          </CCol>
          <CCol :md="6">
            <CFormInput
              id="email"
              v-model="modalCadastrarCliente.novoCliente.email_comercial"
              type="email"
              label="E-mail"
              aria-describedby="email"
            />
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          @click="cancelar"
        >
          Cancelar
        </CButton>
        <CButton
          type="submit"
          color="primary"
        >
          Salvar
        </CButton>
      </CModalFooter>
    </CModal>
  </CForm>
</template>
<style scoped></style>
