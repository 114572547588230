import { format, startOfMonth, parse, set, getTime, addHours } from 'date-fns'
// Remove the zonedTimeToUtc import since it's causing issues
import type PrestacaoDeConta from '~/types/PrestacaoDeConta'

export const usePrestacaoDeContaStore = defineStore('prestacaoDeContaStore', {
  state: () => ({
    urls: {
      despesas: '/financeiro/despesas',
      receitas: '/financeiro/receitas',
    },
    despesas: Array<PrestacaoDeConta>(),
    receitas: Array<ReceitasGise>(),
    receitasExtras: Array<Servico>(),
    receitasResumo: { atos: [], extras: [], receitas_gise: [], receitas_bpo: [] },
    categorias: Array<Categoria>(),
    contas: Array<Conta>(),
    modaisAbertos: [] as { modal: any, aoFechar: any | null }[],
    modalLancarDespesa: {
      exibir: false,
      conta_id: '',
      descricao: '',
      valor: 0,
      vencimento: new Date().toISOString().split('T')[0],
      dataDoPagamento: null,
      observacao: '',
      arquivos: [],
      recorrencias: [],
      criarLembrete: false,
    },
    modalLancarReceita: {
      exibir: false,
      servico_id: '',
      quantidade: 0,
      data: new Date().toISOString().slice(0, 10),
      valor: 0,
      servicos: [],
      listaDeServicos: [],
      editMode: false,
      pagador: {},
      favorecido: {},
      estadoForm: {
        passo: 1,
        favorecidoDiferenteDoPagador: false,
        favorecidoCpfCnpj: '',
        favorecidoNome: '',
        pagadorCpfCnpj: '',
        pagadorNome: '',
      },
    },
    modalDetalhesDespesa: {
      exibir: false,
      despesa_id: '',
    },
    modalConfirmaNovaCategoria: {
      exibir: false,
      nome: '',
    },
    modalConfirmaNovaConta: {
      exibir: false,
      nome: '',
      tipo: 'DEDUTIVEL',
    },
    modalEfetuarPagamento: {
      exibir: false,
      despesa_id: '',
      data_pagamento: null,
    },
    modalEditarValorReceita: {
      exibir: false,
      receita: {},
    },
    modalEditarDespesa: {
      exibir: false,
      id: '',
      conta_id: '',
      descricao: '',
      valor: 0,
      vencimento: null,
      dataDoPagamento: null,
      observacao: '',
      arquivos: [],
      criarLembrete: false,
      calendarioId: '',
      eventoCalendario: null,
    },
    modalConfirmarExclusao: {
      exibir: false,
      id: '',
      mensagem: '',
    },
    modalConfigurarRecorrencia: {
      exibir: false,
      tipo: 'MENSAL', // ou 'SEMANAL'
      quantidade: 1,
      datas: [] as string[],
      despesaBase: null,
      valor: '',
    },
    modalDetalhesReceita: {
      exibir: false,
      transacao: null,
    },
  }),
  actions: {
    async fetchDespesas ({ competencia = null }: {competencia?: string | null} = {}) {
      let url = this.urls.despesas

      if (!competencia) {
        const currentDate = startOfMonth(new Date())
        competencia = format(currentDate, 'yyyy-MM')
      }

      url += `?competencia=${competencia}`
      this.despesas = await useApiStore().request(url) as PrestacaoDeConta[]
    },
    async fetchReceitas ({ competencia }: {competencia?: string }) {
      const receitasResumo = await useApiStore().request(this.urls.receitas + `/${competencia}`, {
        method: 'GET',
      })

      this.receitasResumo = receitasResumo

      if (this.receitasResumo.receitas_bpo) {
        this.receitasResumo.receitas_bpo = this.receitasResumo.receitas_bpo.sort((a, b) => {
          if (a.descricao < b.descricao) {
            return -1
          }
          if (a.descricao > b.descricao) {
            return 1
          }
          return 0
        })
      }

      this.receitas = receitasResumo.receitas_gise
      this.receitasExtras = receitasResumo.extras
    },
    async fetchCategorias () {
      const categorias: Array<Categoria> = await useApiStore().request('/financeiro/categorias') as Array<Categoria>
      this.categorias = categorias
    },
    async fetchContas () {
      const contas: Array<Conta> = await useApiStore().request('/gerencia/conta') as Array<Conta>
      this.contas = contas
    },
    resetarModalLancarDespesa () {
      this.modalLancarDespesa = {
        exibir: false,
        conta_id: '',
        descricao: '',
        valor: 0,
        vencimento: new Date().toISOString().split('T')[0],
        dataDoPagamento: null,
        observacao: '',
        arquivos: [],
        recorrencias: [],
        criarLembrete: false,
      }
    },
    prepararModeloDespesa (values: any): Despesa {
      return {
        conta_id: values.conta_id,
        descricao: values.descricao,
        valor: parseFloat(values.valor.toString().replace(',', '.')),
        vencimento: new Date(values.vencimento),
        data_pagamento: values.dataDoPagamento ? new Date(values.dataDoPagamento) : null,
        observacao: values.observacao || null,
      }
    },
    async salvarDespesa (values: any | any[]) {
      try {
        const despesas = Array.isArray(values) ? values : [values]
        const results = await Promise.all(despesas.map(async (despesa) => {
          const arquivos = []
          if (despesa.arquivos?.length > 0) {
            arquivos.push(despesa.arquivos)
          }

          const despesaData = {
            ...this.prepararModeloDespesa(despesa),
            arquivos,
          }

          const formData = new FormData()
          formData.append('despesa', JSON.stringify(despesaData))

          if (despesa.arquivos && despesa.arquivos.length > 0) {
            despesa.arquivos.forEach((arquivo) => {
              formData.append('arquivos[]', arquivo)
            })
          }

          const response = await useApiStore().upload(this.urls.despesas, formData)
          if (!response.id) {
            throw new Error('Erro ao salvar despesa')
          }
          if (despesa.criarLembrete) {
            try {
              await this.criarLembreteCalendario(response)
            } catch (error: any) {
              if (error.status === 403 || error.message.includes('não tem permissão')) {
                useStore().toasts.push({
                  content: error.message || 'Você não tem permissão para criar eventos neste calendário',
                  color: 'warning',
                  // timeout: 8000 // Give more time to read the message
                })
              } else {
                useStore().toasts.push({
                  content: 'Despesa salva, mas não foi possível criar o lembrete no calendário',
                  color: 'warning',
                  // timeout: 8000
                })
              }
            }
          }

          return response
        }))

        useStore().toasts.push({
          content: `Despesa${despesas.length > 1 ? 's' : ''} salva${despesas.length > 1 ? 's' : ''} com sucesso`,
          color: 'success',
        })

        return results.length === 1 ? results[0] : results
      } catch (error) {
        console.error(error)
        useStore().toasts.push({
          content: error.message || 'Erro ao salvar despesa',
          color: 'danger',
        })
        throw error
      } finally {
        useStore().loading = false
      }
    },
    async criarLembreteCalendario (despesa: any) {
      try {
        // Parse the vencimento date and set it to 7am
        const startTime = parse(despesa.vencimento, 'yyyy-MM-dd', new Date())
        const reminderTime = set(startTime, { hours: 7, minutes: 0, seconds: 0, milliseconds: 0 })

        // Add 3 hours to compensate for timezone (assuming UTC-3)
        const adjustedTime = addHours(reminderTime, 3)

        const calendarData = {
          title: `Vencimento: ${despesa.descricao}`,
          description: `Valor: R$ ${despesa.valor}\nObservação: ${despesa.observacao || 'Nenhuma'}`,
          start_time: getTime(adjustedTime),
          end_time: getTime(adjustedTime),
        }

        const larkStore = useLarkStore()

        if (!larkStore.calendarId) {
          throw new Error('Nenhum calendário padrão configurado')
        }

        await larkStore.createCalendarEvent(larkStore.calendarId, calendarData, despesa)
      } catch (error) {
        console.error('Erro ao criar lembrete:', error.message)
        useStore().toasts.push({
          content: 'Erro ao criar lembrete: ' + error.message,
          color: 'warning',
        })
      }
    },
    async salvarCategoria (nome: string) {
      try {
        const categoria = await useApiStore().request('/financeiro/categoria', {
          method: 'POST',
          body: { nome },
        })
        return categoria
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    resetarModalAdicionarCategoria () {
      this.modalConfirmaNovaCategoria = {
        exibir: false,
        nome: '',
      }
    },
    salvarConta (nome: string, tipo: string) {
      try {
        return useApiStore().request('/gerencia/conta', {
          method: 'POST',
          body: { nome, tipo },
        })
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    resetarModalAdicionarConta () {
      this.modalConfirmaNovaConta = {
        exibir: false,
        nome: '',
        tipo: 'DEDUTIVEL',
      }
    },
    resetarModalEfetuarPagamento () {
      this.modalEfetuarPagamento = {
        exibir: false,
        despesa_id: '',
        data_pagamento: null,
      }
    },
    async efetuarPagamento () {
      try {
        const pagamento = await useApiStore().request(`/financeiro/despesas/${this.modalEfetuarPagamento.despesa_id}/pagar`, {
          method: 'POST',
          body: { data_pagamento: this.modalEfetuarPagamento.data_pagamento },
        })
        return pagamento
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    resetarModalLancarReceita () {
      this.modalLancarReceita = {
        exibir: false,
        servico_id: '',
        data: new Date().toISOString().slice(0, 10),
        quantidade: 0,
        valor: 0,
        pagador: {},
        favorecido: {},
        servicos: [],
        listaDeServicos: this.modalLancarReceita.listaDeServicos,
        editMode: false,
        estadoForm: {
          passo: 1,
          favorecidoDiferenteDoPagador: false,
          favorecidoCpfCnpj: '',
          favorecidoNome: '',
          pagadorCpfCnpj: '',
          pagadorNome: '',
        },
      }
    },
    validarCpfCnpj (values: any) {
      if (!values.favorecidoDiferenteDoPagador) {
        values.favorecidoCpfCnpj = values.pagadorCpfCnpj
        values.favorecidoNome = values.pagadorNome
      }
    },
    async adicionarReceita () {
      try {
        await console.log('add receita')
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    adicionarPagamentoNaReceita (pagamento: any) {
      try {
        let novoPagamentoData
        if (Array.isArray(pagamento)) {
          novoPagamentoData = pagamento
        } else {
          pagamento.dataBase = this.modalLancarReceita.data
          novoPagamentoData = [useOrcamentoStore().prepararModeloNovoPagamento(pagamento)]
        }
        useOrcamentoStore().orcamento.pagamentos = [...useOrcamentoStore().orcamento.pagamentos ?? [], ...novoPagamentoData]
        // const pagamentoData = useOrcamentoStore().prepararModeloPagamento(novoPagamentoData)
        return true
      } catch (err) {
        return err
      }
    },
    resetarModalEditarValorReceita () {
      this.modalEditarValorReceita = {
        exibir: false,
        receita: {},
      }
    },
    async editarValorReceita (receita) {
      try {
        await useApiStore().request(`/financeiro/receitas/${receita.id}`, {
          method: 'PUT',
          body: { valor: parseFloat(receita.valor.replace(',', '.')) },
        })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    prepararModeloNovaReceita (): BpoNovaReceita {
      const model = {
        valor: this.getTotalDosServicos,
        descricao: '',
        servicos: this.modalLancarReceita.servicos,
        pagamentos: useOrcamentoStore().orcamento.pagamentos,
        pagador_pessoa_id: this.modalLancarReceita.pagador.id,
        favorecido_pessoa_id: this.modalLancarReceita.favorecido.id,
        tipo: 'UNICA',
      }

      return model
    },
    async salvarReceita () {
      try {
        const novaReceita = this.prepararModeloNovaReceita()
        const response = await useApiStore().request(this.urls.receitas, {
          method: 'POST',
          body: novaReceita,
        })
        return response
      } catch (error) {
        console.error(error)
        return false
      }
    },
    validarPagadorEFavorecidoReceita () {
      if (!this.modalLancarReceita.pagador?.id) {
        useStore().toasts.push({
          content: 'Pagador não selecionado',
          color: 'danger',
        })
        return false
      }
      if (this.modalLancarReceita.estadoForm.favorecidoDiferenteDoPagador && !this.modalLancarReceita.favorecido?.id) {
        useStore().toasts.push({
          content: 'Favorecido não selecionado',
          color: 'danger',
        })
        return false
      }
      if (!this.modalLancarReceita.favorecido?.id) {
        this.modalLancarReceita.favorecido = this.modalLancarReceita.pagador
        return false
      }
      return true
    },
    validarSePagamentosEstaoPreenchidos () {
      if (this.getTotalDosServicos > this.getTotalDosPagamentos) {
        useStore().toasts.push({
          content: 'Valor pago menor que o valor total dos serviços',
          color: 'danger',
        })
        return false
      }
      if (this.getTotalDosServicos < this.getTotalDosPagamentos) {
        useStore().toasts.push({
          content: 'Valor pago maior que o valor total dos serviços',
          color: 'danger',
        })
        return false
      }
      return true
    },
    async lancarReceita () {
      if (this.validarPagadorEFavorecidoReceita() && this.validarSePagamentosEstaoPreenchidos()) {
        return await this.salvarReceita()
      }
    },
    resetarModalEditarDespesa () {
      this.modalEditarDespesa = {
        exibir: false,
        id: '',
        conta_id: '',
        descricao: '',
        valor: 0,
        vencimento: null,
        dataDoPagamento: null,
        observacao: '',
        arquivos: [],
        criarLembrete: false,
        calendarioId: '',
        eventoCalendario: null,
      }
    },
    async editarDespesa (id: string, formData: FormData) {
      try {
        const response = await useApiStore().upload(
          `${this.urls.despesas}/${id}`,
          formData
        )

        if (!response.id) {
          throw new Error('Erro ao atualizar despesa')
        }

        const despesaData = JSON.parse(formData.get('despesa') as string)
        if (despesaData.criarLembrete) {
          try {
            await this.criarLembreteCalendario(despesaData)
          } catch (error: any) {
            if (error.status === 403 || error.message.includes('não tem permissão')) {
              useStore().toasts.push({
                content: error.message || 'Você não tem permissão para criar eventos neste calendário',
                color: 'warning',
              })
            } else {
              useStore().toasts.push({
                content: 'Despesa atualizada, mas não foi possível criar o lembrete no calendário',
                color: 'warning',
              })
            }
          }
        }

        await this.fetchDespesas()
        return response
      } catch (error) {
        console.error('Erro na store ao atualizar despesa:', error)
        throw error
      }
    },
    async excluirDespesa (id: string) {
      try {
        await useApiStore().request(`${this.urls.despesas}/${id}`, {
          method: 'DELETE',
        })
        await this.fetchDespesas()
        return true
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async deletarEventoCalendario (eventoId: string) {
      try {
        await useApiStore().request(`/financeiro/despesas/evento/${eventoId}`, {
          method: 'DELETE',
        })
        useStore().toasts.push({ content: 'Evento de calendário excluído com sucesso', color: 'success' })
      } catch (error) {
        console.error('Erro ao excluir evento de calendário:', error)
        useStore().toasts.push({ content: 'Erro ao excluir evento de calendário', color: 'danger' })
      }
    },
    resetarModalConfigurarRecorrencia () {
      this.modalConfigurarRecorrencia = {
        exibir: false,
        tipo: 'MENSAL',
        quantidade: 1,
        datas: [],
        despesaBase: null,
      }
    },
    calcularDatasRecorrencia () {
      if (!this.modalConfigurarRecorrencia.despesaBase?.vencimento) {
        this.modalConfigurarRecorrencia.datas = []
        return
      }

      const datas = []
      const despesa = this.modalConfigurarRecorrencia.despesaBase
      const dataBase = new Date(despesa.vencimento + 'T00:00:00')
      const diaBase = dataBase.getDate()

      datas.push(dataBase)

      for (let i = 1; i < this.modalConfigurarRecorrencia.quantidade; i++) {
        let novaData
        if (this.modalConfigurarRecorrencia.tipo === 'MENSAL') {
          // Tenta manter o mesmo dia do mês
          novaData = new Date(dataBase.getFullYear(), dataBase.getMonth() + i, diaBase)

          // Verifica se a data é válida (não rolou para o próximo mês)
          const mesEsperado = (dataBase.getMonth() + i) % 12
          if (novaData.getMonth() !== mesEsperado) {
            // Se inválida, pega o último dia do mês pretendido
            novaData = new Date(dataBase.getFullYear(), dataBase.getMonth() + i + 1, 0)
          }
        } else { // SEMANAL
          novaData = new Date(dataBase)
          novaData.setDate(dataBase.getDate() + (i * 7))
        }

        if (!isNaN(novaData.getTime())) {
          datas.push(novaData)
        }
      }

      this.modalConfigurarRecorrencia.datas = datas.map(data => data.toISOString().split('T')[0])
    },
    prepararDespesasRecorrentes () {
      const despesaBase = this.modalConfigurarRecorrencia.despesaBase
      return this.modalConfigurarRecorrencia.datas.map(data => ({
        ...despesaBase,
        vencimento: data,
      }))
    },
    async downloadComprovantes (despesaId: string, nomeDoArquivo: string) {
      const response = await useApiStore().request(`/financeiro/despesas/${despesaId}/comprovantes`, {
        method: 'GET',
        headers: {
          Accept: 'application/pdf', // Assegure que o tipo de conteúdo está correto
        },
      })

      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `comprovantes_${nomeDoArquivo}.pdf`)
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)
    },
    resetarModalDetalhesReceita () {
      this.modalDetalhesReceita = {
        exibir: false,
        transacao: null,
      }
    },
  },
  getters: {
    getTotalDespesas (state) {
      return state.despesas?.reduce((acc, despesa) => parseFloat(despesa.valor.toString().replace(',', '.')) + acc, 0)
    },
    getTotalReceitas (state) {
      const totalReceitas = state.receitas.reduce((acc, receita) => parseFloat(receita.total.toString().replace(',', '.')) + acc, 0)
      const totalReceitasExtras = state.receitasExtras.reduce((acc, receita) => parseFloat(receita.valor.toString().replace(',', '.')) + acc, 0)
      const totalReceitasBpo = state.receitasResumo.receitas_bpo.reduce((acc, receita) => parseFloat(receita.valor.replace(',', '.')) + acc, 0)
      return totalReceitas + totalReceitasExtras + totalReceitasBpo
    },
    getPagamentosComParcelas: (state) => {
      let pagamentosAgrupados = []

      for (let i = 0; i < state.orcamento.pagamentos?.length; i++) {
        const pagamento = state.orcamento.pagamentos[i]
        const formaDePagamento = pagamento.formaPagamento ?? pagamento.forma
        const quantidadeDeParcelas = pagamento.quantidadeParcelas ?? pagamento.parcelas
        if (formaDePagamento !== 'CARTAO_DE_CREDITO') {
          pagamentosAgrupados[i] = pagamento
        } else if (quantidadeDeParcelas === 1) {
          pagamentosAgrupados[i] = { ...pagamento, children: [] }
          let j = i
          for (j; j < state.orcamento.pagamentos?.length; j++) {
            const proximoPagamento = state.orcamento.pagamentos[j]
            if (([proximoPagamento.forma, proximoPagamento.formaPagamento].includes('CARTAO_DE_CREDITO') && (proximoPagamento.quantidadeParcelas ?? proximoPagamento.parcelas) > 1)) {
              pagamentosAgrupados[i].children.push(proximoPagamento)
              if ((state.orcamento.pagamentos[j + 1]?.quantidadeParcelas ?? state.orcamento.pagamentos[j + 1]?.parcelas) === 1) {
                i = j
                break
              }
            }
          }
        }
      }
      pagamentosAgrupados = pagamentosAgrupados.filter((pagamento) => {
        return pagamento !== undefined
      })
      return pagamentosAgrupados
    },
    getTotalDosServicos: (state) => {
      return state.modalLancarReceita.servicos?.reduce((acc, servico) => acc + (servico.valor * servico.quantidade), 0) ?? 0
    },
    getTotalDosPagamentos: () => {
      return useOrcamentoStore().orcamento.pagamentos?.reduce((acc, pagamento) => acc + pagamento.valor, 0) ?? 0
    },
    getTemPermissaoExcluir: () => {
      return useUsuarioStore().usuarioAtual.cartorio.permissoes.some(permissao => permissao.excluir === 1 && permissao.especialidade_nome === 'financeiro')
    },
    getTemPermissaoEditar: () => {
      return useUsuarioStore().usuarioAtual.cartorio.permissoes.some(permissao => permissao.editar === 1 && permissao.especialidade_nome === 'financeiro')
    },
  },

  persist: {
    paths: [],
  },
})
