import revive_payload_client_4sVQNw7RlN from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/guilherme/www/ntask/financeiro-front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/guilherme/www/ntask/financeiro-front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/guilherme/www/ntask/financeiro-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/Users/guilherme/www/ntask/financeiro-front/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/Users/guilherme/www/ntask/financeiro-front/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_AUP22rrBAc from "/Users/guilherme/www/ntask/financeiro-front/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _1_coreui_0Ysl1m1yyo from "/Users/guilherme/www/ntask/financeiro-front/plugins/1.coreui.js";
import _2_coreui_icons_Ntep5lr1kK from "/Users/guilherme/www/ntask/financeiro-front/plugins/2.coreui-icons.js";
import _3_useBootstrap_client_7IBECU1fQY from "/Users/guilherme/www/ntask/financeiro-front/plugins/3.useBootstrap.client.ts";
import _4_maska_ZNQ8B03Fi2 from "/Users/guilherme/www/ntask/financeiro-front/plugins/4.maska.ts";
import _5_multiselect_jh5mqgnRkf from "/Users/guilherme/www/ntask/financeiro-front/plugins/5.multiselect.ts";
import _6_qrcode_f8IEpePqfi from "/Users/guilherme/www/ntask/financeiro-front/plugins/6.qrcode.ts";
import _7_filepond_client_xyQmNLyefa from "/Users/guilherme/www/ntask/financeiro-front/plugins/7.filepond.client.ts";
import _8_vue_draggable_next_client_KSyQbP3sYv from "/Users/guilherme/www/ntask/financeiro-front/plugins/8.vue-draggable-next.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_AUP22rrBAc,
  _1_coreui_0Ysl1m1yyo,
  _2_coreui_icons_Ntep5lr1kK,
  _3_useBootstrap_client_7IBECU1fQY,
  _4_maska_ZNQ8B03Fi2,
  _5_multiselect_jh5mqgnRkf,
  _6_qrcode_f8IEpePqfi,
  _7_filepond_client_xyQmNLyefa,
  _8_vue_draggable_next_client_KSyQbP3sYv
]