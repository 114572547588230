import type NovoCliente from '~/types/Orcamento/NovoCliente'

export const useClienteStore = defineStore('clienteStore', {
  state: () => ({
    modalCadastrarCliente: {
      exibir: false,
      novoCliente: {
        pessoa_id: '',
        cnpj: '',
        cpf: '',
        cpf_cnpj: '',
        email_comercial: '',
        horario_requisicao: '',
        nome: '',
        razao_social: '',
        sem_cpf_cnpj: '',
        tipo: '',
        tipo_pessoa: '',
        tipo_sem_cpf_cnpj: '',
        whatsapp: '',
        whatsapp_ddi: '',
        pessoa: {} as Pessoa,
      } as NovoCliente,
    },
    modalImportarClienteDeOutroCartorio: {
      exibir: false,
      listaDeUsuarios: [],
    },
    clienteSalvo: {} as NovoCliente,
    index: 0,
    modelState: '',
    modelStateKey: '',
  }),
  actions: {
    async salvarCliente (pessoa?: Pessoa) {
      try {
        if (pessoa) {
          this.modalCadastrarCliente.novoCliente = pessoa
          this.modalCadastrarCliente.novoCliente.cpf_cnpj =
            pessoa.cpf ?? pessoa.cnpj
        }
        this.modalCadastrarCliente.novoCliente.cpf =
          this.modalCadastrarCliente.novoCliente.cpf_cnpj
        this.modalCadastrarCliente.novoCliente.cnpj =
          this.modalCadastrarCliente.novoCliente.cpf_cnpj
        this.modalCadastrarCliente.novoCliente.tipo =
          this.modalCadastrarCliente.novoCliente.cpf_cnpj.length === 11
            ? 'pessoa_fisica'
            : 'pessoa_juridica'
        this.modalCadastrarCliente.novoCliente.tipo_pessoa =
          this.modalCadastrarCliente.novoCliente.cpf_cnpj.length === 11
            ? 'PESSOA_FISICA'
            : 'PESSOA_JURIDICA'
        this.modalCadastrarCliente.novoCliente.tipo_sem_cpf_cnpj = this
          .modalCadastrarCliente.novoCliente.cpf_cnpj
          ? 'N'
          : 'S'
        const cliente = await useApiStore().request('/gerencia/pessoa', {
          method: 'POST',
          body: this.modalCadastrarCliente.novoCliente,
          api: 'base',
        })
        this.clienteSalvo = { ...this.modalCadastrarCliente.novoCliente, id: cliente.id }
        return this.clienteSalvo
      } catch (err) {
        useStore().toasts.push({ content: err.message, color: 'danger' })
      }
    },
    async verificarSePessoaExiste (documento: string) {
      try {
        const pessoa = await useApiStore().request(
          `/gerencia/pessoa/existe/${documento}`,
          { api: 'base' }
        )
        return pessoa
      } catch (err: any) {
        if (err.response?.status === 404) {
          return false
        }
      }
    },
    async verificarSePessoaExisteEmOutroCartorio (documento: string) {
      try {
        const pessoas = await useApiStore().request(
          `/gerencia/pessoa/existe-outros-cartorios/${documento}`,
          { api: 'base' }
        )
        if (pessoas && pessoas.length === 0) {
          return false
        }
        this.modalImportarClienteDeOutroCartorio.listaDeUsuarios = pessoas
        return true
      } catch (err: any) {
        if (err.response.status === 404) {
          return false
        }
      }
    },
    resetarCampos () {
      this.modalCadastrarCliente.novoCliente = {} as NovoCliente
      this.clienteSalvo = {} as NovoCliente
      this.modelState = ''
      this.modelStateKey = ''
    },
  },
  getters: {
  },
  persist: false,
})
