import { default as autenticacaoTSi1AqsPN9Meta } from "/Users/guilherme/www/ntask/financeiro-front/pages/autenticacao.vue?macro=true";
import { default as larkBMpZPPPOlKMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/configuracoes/lark.vue?macro=true";
import { default as indexlZp5ptlv1rMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/dashboard/index.vue?macro=true";
import { default as MainChartcMLooxTtalMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/dashboard/MainChart.vue?macro=true";
import { default as indexUBDhGdbcZ5Meta } from "/Users/guilherme/www/ntask/financeiro-front/pages/deposito-previo/index.vue?macro=true";
import { default as cadastrar_45receitas7tzbHLE93VMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/editar-serventia/cadastrar-receitas.vue?macro=true";
import { default as indexvRstqHG2uhMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/editar-serventia/index.vue?macro=true";
import { default as indexPc4YVm69PIMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/index.vue?macro=true";
import { default as lark_45callbackhFpCF9CWUKMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/lark-callback.vue?macro=true";
import { default as callbackOM93m5sYG9Meta } from "/Users/guilherme/www/ntask/financeiro-front/pages/lark/callback.vue?macro=true";
import { default as _91id_93wHGwR1ILihMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/livro-diario-auxiliar/despesa/[id].vue?macro=true";
import { default as indexEdoehkjNpXMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/livro-diario-auxiliar/despesa/index.vue?macro=true";
import { default as indexJuJ5X6YYjRMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/livro-diario-auxiliar/index.vue?macro=true";
import { default as _91id_93wF6ATkwj2fMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/editar/[id].vue?macro=true";
import { default as _91id_93qC5EbxxSNuMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/imprimir/[id].vue?macro=true";
import { default as indexfRcshAjsjMMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/index.vue?macro=true";
import { default as novoEQCaHf1W4iMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/novo.vue?macro=true";
import { default as _91id_93WJAneheLKLMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/pagar/[id].vue?macro=true";
import { default as _91id_93eDqP56zOEwMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/visualizar/[id].vue?macro=true";
import { default as indexYkiDHS3d34Meta } from "/Users/guilherme/www/ntask/financeiro-front/pages/outros-creditos/index.vue?macro=true";
import { default as indexguJYVgkdv5Meta } from "/Users/guilherme/www/ntask/financeiro-front/pages/prestacao-de-contas/index.vue?macro=true";
import { default as indexjFUiwUeoOoMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/recibo/index.vue?macro=true";
import { default as indexWoDtkQ66rAMeta } from "/Users/guilherme/www/ntask/financeiro-front/pages/repasses/index.vue?macro=true";
export default [
  {
    name: "autenticacao",
    path: "/autenticacao",
    meta: autenticacaoTSi1AqsPN9Meta || {},
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/autenticacao.vue")
  },
  {
    name: "configuracoes-lark",
    path: "/configuracoes/lark",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/configuracoes/lark.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-MainChart",
    path: "/dashboard/MainChart",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/dashboard/MainChart.vue")
  },
  {
    name: "deposito-previo",
    path: "/deposito-previo",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/deposito-previo/index.vue")
  },
  {
    name: "editar-serventia-cadastrar-receitas",
    path: "/editar-serventia/cadastrar-receitas",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/editar-serventia/cadastrar-receitas.vue")
  },
  {
    name: "editar-serventia",
    path: "/editar-serventia",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/editar-serventia/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/index.vue")
  },
  {
    name: "lark-callback",
    path: "/lark-callback",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/lark-callback.vue")
  },
  {
    name: "lark-callback",
    path: "/lark/callback",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/lark/callback.vue")
  },
  {
    name: "livro-diario-auxiliar-despesa-id",
    path: "/livro-diario-auxiliar/despesa/:id()",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/livro-diario-auxiliar/despesa/[id].vue")
  },
  {
    name: "livro-diario-auxiliar-despesa",
    path: "/livro-diario-auxiliar/despesa",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/livro-diario-auxiliar/despesa/index.vue")
  },
  {
    name: "livro-diario-auxiliar",
    path: "/livro-diario-auxiliar",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/livro-diario-auxiliar/index.vue")
  },
  {
    name: "orcamentos-editar-id",
    path: "/orcamentos/editar/:id()",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/editar/[id].vue")
  },
  {
    name: "orcamentos-imprimir-id",
    path: "/orcamentos/imprimir/:id()",
    meta: _91id_93qC5EbxxSNuMeta || {},
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/imprimir/[id].vue")
  },
  {
    name: "orcamentos",
    path: "/orcamentos",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/index.vue")
  },
  {
    name: "orcamentos-novo",
    path: "/orcamentos/novo",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/novo.vue")
  },
  {
    name: "orcamentos-pagar-id",
    path: "/orcamentos/pagar/:id()",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/pagar/[id].vue")
  },
  {
    name: "orcamentos-visualizar-id",
    path: "/orcamentos/visualizar/:id()",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/orcamentos/visualizar/[id].vue")
  },
  {
    name: "outros-creditos",
    path: "/outros-creditos",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/outros-creditos/index.vue")
  },
  {
    name: "prestacao-de-contas",
    path: "/prestacao-de-contas",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/prestacao-de-contas/index.vue")
  },
  {
    name: "recibo",
    path: "/recibo",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/recibo/index.vue")
  },
  {
    name: "repasses",
    path: "/repasses",
    component: () => import("/Users/guilherme/www/ntask/financeiro-front/pages/repasses/index.vue")
  }
]