import type FormNovoOrcamento from '~/types/Orcamento/FormNovoOrcamento'

export const useStore = defineStore('store', {
  state: () => ({
    key: 0,
    loading: false,
    sidebarVisible: true,
    sidebarUnfoldable: true,
    theme: 'light',
    formNovoOrcamento: {} as FormNovoOrcamento,
    modaisAbertos: [] as { modal: any, aoFechar: any | null }[],
    cartorio: {
      cor: '',
    },
    usuario: {
      foto: '',
    },
    toasts: [] as Array<{ title?: string, content: string; color: string }>,
    competencia: {
      mes: new Date().getMonth() + 1,
      ano: new Date().getFullYear(),
    },
  }),
  actions: {
    toggleUnfoldable () {
      this.sidebarUnfoldable = !this.sidebarUnfoldable
    },
    formatarDinheiro (valor: number | string, cifrao?: boolean, removerSinalNegativo?: boolean): string {
      // Tenta converter para número, substituindo vírgula por ponto para casos de entrada como "100,00"
      const numero = typeof valor === 'string' ? parseFloat(valor.replace(',', '.')) : valor

      if (isNaN(numero)) {
        // Se o número não for válido, retorna uma mensagem de erro ou valor padrão
        return 'Valor inválido'
      }

      const valorFormatado = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(numero)

      // return cifrao ? valorFormatado : valorFormatado.replace('R$', '').trim()
      const valorParaExibir = cifrao ? valorFormatado : valorFormatado.replace('R$', '').trim()
      if (!removerSinalNegativo) {
        return valorParaExibir
      }
      return valorParaExibir.replace('-', '')
    },
    formatarPorcentagem: (valor: number): string => {
      return new Intl.NumberFormat('pt-BR', {
        style: 'percent',
        minimumFractionDigits: 2,
      }).format(valor)
    },
    formatarCns (valor: string) {
      if (typeof valor !== 'undefined') {
        return valor.replace(/([0-9]{2})([0-9]{3})([0-9]{1})/, '$1.$2-$3')
      }
    },
    formatarCpfCnpj (input: string) {
      if (!input) {
        return ''
      }
      if (input.length === 11) {
        return input && input.replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/, '$1.$2.$3-$4')
      }

      if (input.length === 14) {
        return input && input.replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/, '$1.$2.$3/$4-$5')
      }

      return input
    },
    formatarData: (date: Date | string) => {
      if (!date) {
        return ''
      }

      let data

      if (typeof date === 'string') {
        // Verifica se o formato é yyyy-mm-dd ou dd/mm/yyyy
        if (date.includes('-')) {
          // Formato yyyy-mm-dd
          const partes = date.split('-')
          const ano = parseInt(partes[0], 10)
          const mes = parseInt(partes[1], 10) - 1 // Ajusta para índice de mês iniciando em 0
          const dia = parseInt(partes[2], 10)

          data = new Date(ano, mes, dia)
        } else if (date.includes('/')) {
          // Formato dd/mm/yyyy
          const partes = date.split('/')
          const dia = parseInt(partes[0], 10)
          const mes = parseInt(partes[1], 10) - 1
          const ano = parseInt(partes[2], 10)

          data = new Date(ano, mes, dia)
        } else {
          return 'Data inválida'
        }
      } else {
        data = new Date(date)
      }

      if (isNaN(data.getTime())) {
        return 'Data inválida'
      }

      return data.toLocaleDateString('pt-BR')
    },
    formatarDateTime: (dateTime: Date | string) => {
      if (!dateTime) {
        return ''
      }
      const date = new Date(dateTime)
      return date.toLocaleString('pt-BR')
    },
    formatarCNPJ: (cnpj: string) => {
      if (cnpj) {
        return cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        )
      }
      return cnpj
    },
    formatarNumeroInteiro (input: string): string {
      return input.replace(/\D/g, '')
    },
    formatarNumeroDecimal (value: string): string {
      if (typeof value === 'string') {
        return value.replace(/\./g, '').replace(',', '.')
      }
      return value
    },
    trimmarString (input: string, tamanho: number = 40): string {
      if (input?.length > tamanho) {
        return `${input.substring(0, tamanho)}...`
      }
      return input
    },
    toMonthYear (date: Date) {
      return date.toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
      })
    },
    isValidDate (day: number, month: number, year: number): boolean {
      // Meses com 31 dias
      const months31 = [1, 3, 5, 7, 8, 10, 12]
      // Meses com 30 dias
      const months30 = [4, 6, 9, 11]

      // Verificar ano bissexto
      const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)

      // Verificar validade do mês
      if (month < 1 || month > 12) { return false }

      // Verificar validade do dia
      if (day < 1 || day > 31) { return false }

      // Verificar meses com 31 dias
      if (months31.includes(month) && day <= 31) { return true }

      // Verificar meses com 30 dias
      if (months30.includes(month) && day <= 30) { return true }

      // Verificar fevereiro
      if (month === 2) {
        if (isLeapYear && day <= 29) { return true }
        if (!isLeapYear && day <= 28) { return true }
      }

      return false
    },
    converterDataParaCompetencia (data: Date | string): string {
      const date = new Date(data)
      return date.toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
      }).split('/').reverse().join('-')
    },
    retornarClasseDeValidacao (meta) { // recebe um input do vee-validate
      if (meta.value === '') {
        return ''
      }
      if (meta.touched && meta.validated) {
        return meta.valid ? 'is-valid' : 'is-invalid'
      }
      return ''
    },
    substituirModal (modalParaAbrir, modalAberto, focar = false) {
      if (!focar) {
        this.modaisAbertos.push({ modal: modalParaAbrir, aoFechar: modalAberto })
      }
      modalAberto.exibir = false
      modalParaAbrir.exibir = true
    },
    reorganizarModais (modalParaAbrir: any) {
      const modalParaExibir = useStore().modaisAbertos.find(modal => modal.modal === modalParaAbrir)
      if (modalParaExibir) {
        modalParaAbrir.exibir = false
        modalParaExibir.aoFechar.exibir = true
      } else {
        modalParaAbrir.exibir = true
      }
    },
    setCompetencia (mes: number, ano: number) {
      this.competencia = { mes, ano }
    },
  },
  getters: {
    competenciaFormatada (): string {
      return `${this.competencia.ano}-${String(this.competencia.mes).padStart(2, '0')}`
    },
  },
  persist: {
    paths: ['cartorio', 'competencia'],
  },
})
