<script setup>
const sidebarVisible = ref(false)
const config = useRuntimeConfig()
const urlLoginBase = computed(() => {
  return config.public.loginUrl.replace('/autenticacao', '')
})

const listaDeModulos = [
  {
    nome: 'controle_processos',
    label: 'Controle de processos',
    icone: 'trello.png',
    url: `${urlLoginBase.value}/jwt/${useAutenticacaoStore().token}?continuar=${urlLoginBase.value}`,
  },
  {
    nome: 'rtd',
    label: 'RTD',
    icone: 'rtd2.png',
    url: `https://rtd.ntask.app/#/autenticacao/?token=${useAutenticacaoStore().token}`,
  },
  {
    nome: 'rcpj',
    label: 'RCPJ',
    icone: 'rcpj2.png',
    url: `https://rcpj.ntask.app/#/autenticacao/?token=${useAutenticacaoStore().token}`,
  },
  {
    nome: 'acervo',
    label: 'Acervo',
    icone: 'acervo2.png',
    url: `${urlLoginBase.value}/jwt/${useAutenticacaoStore().token}?continuar=${urlLoginBase.value}/acervo`,
  },
  {
    nome: 'admin',
    label: 'Admin',
    icone: 'admin.png',
    url: `${urlLoginBase.value}/jwt/${useAutenticacaoStore().token}?continuar=${urlLoginBase.value}/admin`,
  },
  {
    nome: 'imoveis',
    label: 'Imóveis',
    icone: 'imoveis2.png',
    url: `${urlLoginBase.value}/jwt/${useAutenticacaoStore().token}?continuar=${urlLoginBase.value}/imoveis`,
  },
  {
    nome: 'mosaico',
    label: 'Mosaico',
    icone: 'mosaico2.png',
    url: `https://mosaico.ntask.app/login/Bearer ${useAutenticacaoStore().token}`,
  },
  {
    nome: 'contabil',
    label: 'Contábil',
    icone: 'contabil2.png',
    url: `https://contador.ntask.app/#/autenticacao/?token=${useAutenticacaoStore().token}`,
  },
  // {
  //   label: 'Protesto',
  //   icone: 'protesto2.png',
  //   url: urlControleDeProcessos.value,
  // },
  {
    nome: 'notas',
    label: 'Notas',
    icone: 'notas2.png',
    url: `${urlLoginBase.value}/jwt/${useAutenticacaoStore().token}?continuar=${urlLoginBase.value}/notas`,
  }
].sort((a, b) => a.nome.localeCompare(b.nome))

function exibirModulo (moduloParaExibir) {
  const modulo = useAutenticacaoStore().modulos?.find(m => m.nome === moduloParaExibir.nome)

  // TODO: Verificar regras para exibir esses módulos
  if (['contabil', 'mosaico'].includes(moduloParaExibir.nome)) {
    return true
  }

  if (!modulo) {
    return false
  }
  if (modulo.inserir === 1 || modulo.alterar === 1 || modulo.excluir === 1 || modulo.visualizar === 1) {
    return true
  }
  return false
}
</script>
<template>
  <div title="Módulos">
    <CHeaderNav class="ms-auto">
      <CNavItem>
        <CHeaderToggler class="ps-1 text-white" @click="sidebarVisible = !sidebarVisible">
          <CIcon icon="cilApplications" size="lg" />
        </CHeaderToggler>
      </CNavItem>
    </CHeaderNav>
    <CSidebar
      class="border-end overflow-auto"
      color-scheme="light"
      position="fixed"
      placement="end"
      :unfoldable="useStore().sidebarUnfoldable"
      :visible="sidebarVisible"
    >
      <CSidebarHeader class="border-bottom py-0 border-start-secondary bg-body-secondary text-center fw-semibold text-body-secondary text-uppercase small d-flex justify-content-between">
        <CListGroupItem class="border-start-4">
          Módulos
        </CListGroupItem>
        <button class="btn btn-link" @click="sidebarVisible = false">
          <CIcon icon="cil-x" size="lg" />
        </button>
      </CSidebarHeader>
      <CListGroup>
        <template v-for="modulo in listaDeModulos" :key="modulo.nome">
          <CListGroupItem v-if="exibirModulo(modulo)" class="border-start-4 border-start-secondary p-0">
            <CNavLink target="_blank" :href="modulo.url" class="modulo-nome">
              <img :src="`/img/icons/modulos/${modulo.icone}`" :alt="modulo.label" class="modulo-icon">
              {{ modulo.label }}
            </CNavLink>
          </CListGroupItem>
        </template>
      </CListGroup>
      <CSidebarFooter class="border-top d-none d-lg-flex">
      <!-- sidebar footer. Logout talvez?  -->
      </CSidebarFooter>
    </CSidebar>
  </div>
</template>
<style scoped>
:deep(.dropdown-menu.show){
  width: 400px;
}
.modulo-icon{
  width: 2.5em;
  height: 2.5em;
  margin-right: 5px;
}
.modulo-nome{
  font-size: 14px;
  text-transform: uppercase;
}
</style>
