import type RequestOptions from '@/types/RequestOptions'
export const useApiStore = defineStore('apiStore', {
  state: () => ({
  }),
  actions: {
    async request (url: string, options: RequestOptions = {}) {
      const config = useRuntimeConfig()

      try {
        useStore().loading = true
        const { method = 'GET', headers: optionsHeaders, body: requestBody, ...restOptions } = options
        const defaultHeaders = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${useAutenticacaoStore().retornarToken}`,
        }
        const mergedHeaders = {
          ...defaultHeaders,
          ...optionsHeaders,
        }
        const defaultOptions: RequestInit = {
          method,
          headers: mergedHeaders,
          body: requestBody !== undefined ? JSON.stringify(requestBody) : undefined,
          ...restOptions,
        }
        const response = await $fetch<Response>(config.public.baseURL + url, defaultOptions)
        return response
      } catch ({ response, data }) {
        // if error is 4xx, redirect to login
        if (!response || ([400, 401, 402, 403, 404].includes(response.status) && data.error && ['token_invalid', 'token_expired', 'token_not_provided'].includes(data.error))) {
          // debugger
          // console.log('Erro de autenticação')
          useAutenticacaoStore().$reset()
          useUsuarioStore().$reset()
          return navigateTo(this.redirectBackWhenSignedUrl(), {
            external: true,
          })
        } else {
          throw data
        }
      } finally {
        useStore().loading = false
      }
    },
    async upload (url: string, formData: FormData, method = 'POST') {
      const config = useRuntimeConfig()

      try {
        useStore().loading = true
        const response = await $fetch(config.public.baseURL + url, {
          method,
          body: formData,
          data: formData,
          headers: {
            Authorization: `Bearer ${useAutenticacaoStore().retornarToken}`,

          },
        })
        return response
      } catch (error) {
        if (error.data?.message) {
          throw new Error(error.data.message)
        }
        throw new Error(error)
      } finally {
        useStore().loading = false
      }
    },
    redirectBackWhenSignedUrl () {
      const config = useRuntimeConfig()

      return `${config.public.loginUrl}?continuar=${config.public.autenticacaoURL}?token=Bearer {token}`
    },
  },
})
