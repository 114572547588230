<script lang="ts" setup>
import avatar from '@/assets/images/avatars/9.jpg'

const store = useOrcamentoStore()
const { modalImportarClienteDeOutroCartorio, formNovoOrcamento } = storeToRefs(store)

const clienteStore = useClienteStore()

async function selecionarUsuarioParaImportar (pessoa: Pessoa) {
  try {
    const cliente = await clienteStore.salvarCliente(pessoa)
    formNovoOrcamento.value = { ...formNovoOrcamento.value, ...pessoa, pessoa_id: cliente.id }
    modalImportarClienteDeOutroCartorio.value.listaDeUsuarios = []
    modalImportarClienteDeOutroCartorio.value.exibir = false
    useStore().toasts.push({ content: 'Cliente importado com sucesso', color: 'success' })
  } catch (err) {
    useStore().toasts.push({ content: err.message, color: 'danger' })
  }
}

function cancelar () {
  clienteStore.modalCadastrarCliente.novoCliente.cpf_cnpj = formNovoOrcamento.value.cpf_cnpj
  modalImportarClienteDeOutroCartorio.value.exibir = false
  clienteStore.modalCadastrarCliente.exibir = true
}
</script>

<template>
  <CModal
    :visible="modalImportarClienteDeOutroCartorio.exibir"
    aria-labelledby="importarClienteDeOutroCartorioLabel"
    size="lg"
    @close="cancelar"
  >
    <CModalHeader>
      <CModalTitle id="importarClienteDeOutroCartorioLabel">
        Importar Cliente
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CAlert color="info">
        Já existe(m) cadastro(s) com o documento {{ formNovoOrcamento.documento }} em outro cartório. Clique em um dos cadastros abaixo se desejar importar as informações.
      </CAlert>
      <CTable align="middle" class="mb-0 border" hover responsive>
        <CTableHead color="light">
          <CTableRow>
            <CTableHeaderCell class="text-center" colspan="2">
              <CIcon name="cil-people" />
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="pessoa, index in modalImportarClienteDeOutroCartorio.listaDeUsuarios" :key="index" role="button" @click="selecionarUsuarioParaImportar(pessoa)">
            <CTableDataCell class="text-center">
              <CAvatar
                size="md"
                :src="avatar"
              />
            </CTableDataCell>
            <CTableDataCell>
              <div>{{ pessoa.nome }}</div>
              <div class="small text-medium-emphasis">
                <span>Cartório:</span> {{ `${pessoa.cartorio?.cns} (${pessoa.cartorio?.denominacao})` }}<br>
                <span>Última atualização: </span> {{ pessoa.updated_at }}
              </div>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <span class="mt-2">
        Obs: Se não deseja importar, clique em CANCELAR.
      </span>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="cancelar">
        Cancelar
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<style scoped></style>
