<script lang="ts" setup>
import type Pessoa from '~/types/Pessoa'

const store = useClienteStore()
const prestacaoDeContasStore = usePrestacaoDeContaStore()
const { modalImportarClienteDeOutroCartorio } = storeToRefs(store)

async function selecionarUsuarioParaImportar (pessoa: Pessoa) {
  try {
    const cliente = await store.salvarCliente(pessoa)
    if (!prestacaoDeContasStore[store.modelState]) {
      (useOrcamentoStore())[store.modelState][store.modelStateKey] = cliente
    } else {
      prestacaoDeContasStore[store.modelState][store.modelStateKey] = cliente
    }
    modalImportarClienteDeOutroCartorio.value.listaDeUsuarios = []
    modalImportarClienteDeOutroCartorio.value.exibir = false
    useStore().reorganizarModais(store.modalImportarClienteDeOutroCartorio)
    useStore().toasts.push({ content: 'Cliente importado com sucesso', color: 'success' })
  } catch (err) {
    useStore().toasts.push({ content: err.message, color: 'danger' })
  }
}
function cancelar () {
  // prestacaoDeContasStore.resetObject(prestacaoDeContasStore[props.modelState][props.index])
  modalImportarClienteDeOutroCartorio.value.exibir = false
  if (['modalLancarReceita'].includes(store.modelState)) {
    useStore().substituirModal(prestacaoDeContasStore[store.modelState], modalImportarClienteDeOutroCartorio)
  }
  store.resetarCampos()
}
</script>
<template>
  <CModal
    :visible="modalImportarClienteDeOutroCartorio.exibir"
    aria-labelledby="importarClienteDeOutroCartorioLabel"
    size="lg"
    @close="cancelar"
  >
    <CModalHeader>
      <CModalTitle id="importarClienteDeOutroCartorioLabel">
        Importar Cliente
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <CAlert color="info">
        Já existe(m) cadastro(s) com esse documento em outro cartório. Clique em um dos cadastros abaixo se desejar importar as informações.
      </CAlert>
      <CTable align="middle" class="mb-0 border" hover responsive>
        <CTableHead color="light">
          <CTableRow>
            <CTableHeaderCell class="text-center" colspan="2">
              <CIcon name="cil-people" />
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="pessoa, index in modalImportarClienteDeOutroCartorio.listaDeUsuarios" :key="index" role="button" @click="selecionarUsuarioParaImportar(pessoa)">
            <CTableDataCell class="text-center">
              <CIcon icon="cilUser" class="text-primary" size="xl" />
            </CTableDataCell>
            <CTableDataCell>
              <div>{{ pessoa.nome }}</div>
              <div class="small text-medium-emphasis">
                <!-- <span>Cartório:</span> {{ `${pessoa.cartorio?.cns} (${pessoa.cartorio?.denominacao})` }}<br> -->
                <span>Última atualização: </span> {{ pessoa.updated_at }}
              </div>
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <span class="mt-2">
        Obs: Se não deseja importar, clique em CANCELAR.
      </span>
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" @click="cancelar">
        Cancelar
      </CButton>
    </CModalFooter>
  </CModal>
</template>
<style scoped></style>
