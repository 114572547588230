<script lang="ts" setup>
const store = useOrcamentoStore()
const { modalAdicionarAto, tiposDeAtos } = storeToRefs(store)

const modalElement = ref(null)

async function atualizarListaDeAtos (tipo: string) {
  useStore().loading = true
  await store.fetchAtos(tipo)
  useStore().loading = false
}

watch(() => modalAdicionarAto.value.tipoDeAto, async (value) => {
  await atualizarListaDeAtos(value)
})

watch(() => store.modalAdicionarAto.exibir, async (value) => {
  if (value) {
    await store.fetchAtos(modalAdicionarAto.value.tipoDeAto ?? 'TODOS')
  }
})

function validar () {
  modalElement.value.querySelectorAll('[required]').forEach((element) => {
    if (!element.value) {
      element.classList.add('is-invalid')
      throw new Error('Preencha todos os campos obrigatórios')
    } else {
      element.classList.remove('is-invalid')
      element.classList.add('is-valid')
    }
  })

  if (!modalAdicionarAto.value.atoSelecionado.id) {
    throw new Error('Selecione um ato')
  }
  const quantidade = modalAdicionarAto.value.atoSelecionado.quantidadeDeAtos ?? modalAdicionarAto.value.atoSelecionado.quantidade
  if (!quantidade || quantidade < 1) {
    throw new Error('Informe a quantidade')
  }
  if (!modalAdicionarAto.value.atoSelecionado.nome_ato) {
    throw new Error('Informe a descrição do serviço')
  }
  return true
}

async function adicionarAto () {
  try {
    useStore().loading = true
    validar()
    await store.adicionarAto()
    useStore().toasts.push({ content: 'Ato adicionado com sucesso', color: 'success' })
    modalAdicionarAto.value.camposExtra = []
    store.resetarAtoSelecionado()
  } catch (err) {
    useStore().toasts.push({ content: err.message, color: 'danger' })
    console.error(err)
  } finally {
    useStore().loading = false
  }
}

async function change (emolumentoId: string) {
  if (emolumentoId && emolumentoId !== '') {
    modalAdicionarAto.value.atoSelecionado = modalAdicionarAto.value.listaDeAtos.find(ato => ato.id === emolumentoId)
    modalAdicionarAto.value.atoSelecionado.quantidadeDeAtos = 1
    modalAdicionarAto.value.camposExtra = await store.fetchCamposEmolumento(emolumentoId)
  }
}

function mudarTipoDeAto (tipo: string) {
  store.resetarAtoSelecionado()
  modalAdicionarAto.value.atoSelecionadoId = ''
  modalAdicionarAto.value.tipoDeAto = tipo
}

</script>

<template>
  <div ref="modalElement">
    <CModal
      :visible="modalAdicionarAto.exibir"
      backdrop="static"
      aria-labelledby="importarClienteDeOutroCartorioLabel"
      size="lg"
      @close="() => { modalAdicionarAto.exibir = false }"
    >
      <CModalHeader>
        <CModalTitle id="importarClienteDeOutroCartorioLabel">
          Adicionar Ato Registral/Notarial
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>Qual o módulo do ato?</p>
        <CButtonGroup role="group" class="mb-3" aria-label="Tipo de ato">
          <CButton
            v-for="tipo, index in tiposDeAtos"
            :key="index"
            color="primary"
            variant="outline"
            :active="modalAdicionarAto.tipoDeAto == tipo"
            @click="mudarTipoDeAto(tipo)"
          >
            {{ tipo }}
          </CButton>
        </CButtonGroup>
        <Multiselect
          v-model="modalAdicionarAto.atoSelecionadoId"
          value-prop="id"
          label="nome_ato"
          :options="modalAdicionarAto.listaDeAtos"
          :searchable="true"
          placeholder="Selecione ou pesquise pelo ato"
          class="mb-3"
          @change="(emolumentoId: string) => change(emolumentoId)"
        />
        <CFormInput
          id="descricaoDoServico"
          v-model="modalAdicionarAto.atoSelecionado.nome_ato"
          class="mb-3"
          type="text"
          label="Descrição do serviço"
          aria-describedby="descricaoDoServico"
        />
        <CRow>
          <CCol :md="4">
            <CFormInput
              id="quantidadeDeAtos"
              v-model.number="modalAdicionarAto.atoSelecionado.quantidadeDeAtos"
              type="number"
              label="Quantidade de atos"
              placeholder="Informe um número inteiro"
              aria-describedby="quantidadeDeAtos"
              min="1"
            />
          </CCol>
          <OrcamentosModalAdicionarAtoCamposExtra v-if="modalAdicionarAto.atoSelecionadoId" :ato="modalAdicionarAto.atoSelecionado" :campos-extra="modalAdicionarAto.camposExtra" />
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color="light" @click="store.cancelarAdicaoDeAto">
          Cancelar
        </CButton>
        <CButton color="success" @click="adicionarAto">
          Salvar
        </CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>
<style scoped></style>
